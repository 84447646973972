#report_modal .modal-body {
  height: 240px;
}

#report_modal .modal-body textarea {
  resize: none;
}

textarea#report_content {
  height: auto;
}

.list-group-item .video-report-link,
.view-slide .photo-report-link {
  display: none;
}

.list-group-item:hover .video-report-link,
.view-slide:hover .photo-report-link {
  display: block;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy9yZXBvcnRzL2NzcyIsInNvdXJjZXMiOlsiaW5kZXguc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFOzs7QUFHRjtBQUFBO0VBRUU7OztBQUdGO0FBQUE7RUFFRSIsInNvdXJjZXNDb250ZW50IjpbIiNyZXBvcnRfbW9kYWwgLm1vZGFsLWJvZHkge1xuICBoZWlnaHQ6IDI0MHB4O1xufVxuXG4jcmVwb3J0X21vZGFsIC5tb2RhbC1ib2R5IHRleHRhcmVhIHtcbiAgcmVzaXplOiBub25lO1xufVxuXG50ZXh0YXJlYSNyZXBvcnRfY29udGVudCB7XG4gIGhlaWdodDogYXV0bztcbn1cblxuLmxpc3QtZ3JvdXAtaXRlbSAudmlkZW8tcmVwb3J0LWxpbmssXG4udmlldy1zbGlkZSAucGhvdG8tcmVwb3J0LWxpbmsge1xuICBkaXNwbGF5OiBub25lO1xufVxuXG4ubGlzdC1ncm91cC1pdGVtOmhvdmVyIC52aWRlby1yZXBvcnQtbGluayxcbi52aWV3LXNsaWRlOmhvdmVyIC5waG90by1yZXBvcnQtbGluayB7XG4gIGRpc3BsYXk6IGJsb2NrO1xufVxuIl19 */