/* Colour and font changes built on the Spacelab Bootswatch. */

/* COLOURS */

$link-color: #6D94BF;
$primary-color: #446E9B;
$error-color: #A94442;

/* NAVBARS */

.navbar-inverse {
  background-image: none !important;
}

ul.dropdown-menu {
  box-shadow: none !important;
  margin-top: 2px !important;
}

.navbar-brand {
  font-family: "Alegreya SC", Georgia;
  font-size: 1.7em;
}

.navbar-brand,
.dropdown-toggle,
.navbar-toggle {
  color: #ffffff !important;
}

.nav-stacked > li {
  text-align: center;
}

/* HEADERS */

h1, h2, h3, h4, h5, h6 {
  font-family: "Alegreya", Georgia;
}

/* JUMBOTRONS */

.jumbotron > h1 {
  font-family: "Alegreya SC", Georgia;
}

.jumbotron {
  background-color: #ffffff;

  & a {
    font-size: 0.7em;
  }
}

/* LINKS/BUTTONS */

a, a:hover, a:visited, .btn-link:hover, h3.panel-title a {
  color: $link-color;

  &.btn-default, &.btn-primary, &.btn-success,
  &.btn-info, &.btn-warning, &.btn-danger {
    color: #ffffff !important;
  }
}

a strong, strong a {
  color: $primary-color !important;
}

.btn-link {
  color: $link-color;
}

/* INPUTS */

.form-control:focus {
  border-color: $link-color;
  box-shadow: none;
}

.field_with_errors {
  & input.form-control:focus {
    border-color: $error-color !important;
  }

  & span.error,
  & label {
    color: $error-color;
  }
}

/* THUMBNAILS */

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active,
a > img.img-thumbnail:hover,
a > img.img-thumbnail:focus,
a > img.img-thumbnail:active,
.cycle-slide img.img-thumbnail:hover,
.cycle-slide img.img-thumbnail:focus,
.cycle-slide img.img-thumbnail:active {
  border-color: $link-color;
}

/* PAGINATION */
/* (Yeti style with Spacelab colours) */

.pagination > li > a {
  border: 0;
  margin: 2px;

  &:hover {
    border-radius: 4px;
  }
}

.pagination > .active > a {
  color: #ffffff !important;
  background-color: $primary-color !important;
  border-radius: 4px;
}

.pagination > .gap > a {
  background-color: #ffffff !important;
}

/* BOOTSTRAP PLUGINS */

.select2-highlighted {
  background: $primary-color !important;
}

.select2-dropdown-open,
.select2-drop-active {
  border-color: $link-color !important;
}

.bootstrap-datepicker-widget .active {
  background-color: $primary-color !important;
}

.datepicker {
  padding: 0 !important;
}

/* Image-picker */
.thumbnail.selected {
  background: $primary-color !important;
}
