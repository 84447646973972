.about-block h3 {
  margin-bottom: 0;
}
.about-block p {
  margin-bottom: 20px;
}

a.dummy:hover {
  cursor: default !important;
  text-decoration: none !important;
}

#announcement {
  text-align: center;
}
#announcement > .alert {
  display: inline-block;
  padding: 10px 15px;
}
#announcement > .alert > a, #announcement > .alert > span {
  display: inline-block;
  height: 2em;
  vertical-align: 0.35em;
  margin-left: 10px;
}

section.home {
  margin-top: 20px;
}
section.home .js-masonry {
  margin: 0 auto;
}

#home-slideshow-caption {
  width: 360px;
  min-height: 3em;
  margin: 15px auto 50px auto;
}

a.btn-caption {
  width: 100%;
  background-color: #FFFFFF;
  color: #446E9B !important;
  font-size: 1.2em;
}
a.btn-caption:hover {
  background-color: #3E648D;
  color: #FFFFFF !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy9wYWdlcy9jc3MiLCJzb3VyY2VzIjpbImluZGV4LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFBTzs7QUFDUDtFQUFNOzs7QUFHUjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7O0FBRUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7OztBQUtOO0VBQ0U7O0FBRUE7RUFDRTs7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmFib3V0LWJsb2NrIHtcbiAgJiBoMyB7IG1hcmdpbi1ib3R0b206IDA7IH1cbiAgJiBwIHsgbWFyZ2luLWJvdHRvbTogMjBweDsgfVxufVxuXG5hLmR1bW15OmhvdmVyIHtcbiAgY3Vyc29yOiBkZWZhdWx0ICFpbXBvcnRhbnQ7XG4gIHRleHQtZGVjb3JhdGlvbjogbm9uZSAhaW1wb3J0YW50O1xufVxuXG4jYW5ub3VuY2VtZW50IHtcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xuXG4gICYgPiAuYWxlcnQge1xuICAgIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgICBwYWRkaW5nOiAxMHB4IDE1cHg7XG5cbiAgICAmID4gYSwgJiA+IHNwYW4ge1xuICAgICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICAgICAgaGVpZ2h0OiAyZW07XG4gICAgICB2ZXJ0aWNhbC1hbGlnbjogMC4zNWVtO1xuICAgICAgbWFyZ2luLWxlZnQ6IDEwcHg7XG4gICAgfVxuICB9XG59XG5cbnNlY3Rpb24uaG9tZSB7XG4gIG1hcmdpbi10b3A6IDIwcHg7XG5cbiAgJiAuanMtbWFzb25yeSB7XG4gICAgbWFyZ2luOiAwIGF1dG87XG4gIH1cbn1cblxuI2hvbWUtc2xpZGVzaG93LWNhcHRpb24ge1xuICB3aWR0aDogMzYwcHg7XG4gIG1pbi1oZWlnaHQ6IDNlbTtcbiAgbWFyZ2luOiAxNXB4IGF1dG8gNTBweCBhdXRvO1xufVxuXG5hLmJ0bi1jYXB0aW9uIHtcbiAgd2lkdGg6IDEwMCU7XG4gIGJhY2tncm91bmQtY29sb3I6ICNGRkZGRkY7XG4gIGNvbG9yOiAjNDQ2RTlCICFpbXBvcnRhbnQ7XG4gIGZvbnQtc2l6ZTogMS4yZW07XG5cbiAgJjpob3ZlciB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogIzNFNjQ4RDtcbiAgICBjb2xvcjogI0ZGRkZGRiAhaW1wb3J0YW50O1xuICB9XG59XG4iXX0= */