.btn-entry-pager {
  background-image: none !important;
  border-color: white !important;
}

/* ADMIN */

.check {
  color: #36A41E;
  vertical-align: middle !important;
}

textarea.form-control {
  height: 200px;
}
