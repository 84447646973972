.gallery-title-holder {
  position: relative;
}

.gallery-back-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.gallery-description {
  margin: 25px 0;
  text-align: left;
}
