.photographers {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

table.photographers {
  max-width: 450px;

  &:hover {
    cursor: pointer !important;
  }

  & td {
    vertical-align: middle !important;

    &.photographer-image {
      width: 110px !important;
    }

    & h4 {
      text-align: center !important;
    }
  }
}
