/* SPECIFIC ELEMENTS */

#browser-check {
  display: none;
}

.content {
  min-height: 250px;
  text-align: justify;
}

footer {
  clear: both;
  font-size: 0.8em;
}

/* GENERAL STYLES */

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.form {
  max-width: 400px;
}

h1, h2, h3, h4, h5, h6 {
  text-align: left;
}

.table {
  text-align: left;
}

th.right {
  text-align: right;
  width: 100px;
}

.search-field {
  width: 200px;
}

.flash {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.padded {
  margin-top: 28px;
}

.less-padded {
  margin-top: 15px;
}

.clear-and-pad {
  padding-top: 30px;
  clear: both;
}

.btn-padded {
  margin: 8px;
}

/* camera.svg */
img[loading="lazy"] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYWRiNWJkIiBkPSJNNTEyIDE0NHYyODhjMCAyNi41LTIxLjUgNDgtNDggNDhINDhjLTI2LjUgMC00OC0yMS41LTQ4LTQ4VjE0NGMwLTI2LjUgMjEuNS00OCA0OC00OGg4OGwxMi4zLTMyLjljNy0xOC43IDI0LjktMzEuMSA0NC45LTMxLjFoMTI1LjVjMjAgMCAzNy45IDEyLjQgNDQuOSAzMS4xTDM3NiA5Nmg4OGMyNi41IDAgNDggMjEuNSA0OCA0OHpNMzc2IDI4OGMwLTY2LjItNTMuOC0xMjAtMTIwLTEyMHMtMTIwIDUzLjgtMTIwIDEyMCA1My44IDEyMCAxMjAgMTIwIDEyMC01My44IDEyMC0xMjB6bS0zMiAwYzAgNDguNS0zOS41IDg4LTg4IDg4cy04OC0zOS41LTg4LTg4IDM5LjUtODggODgtODggODggMzkuNSA4OCA4OHoiPjwvcGF0aD48L3N2Zz4=);
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: center;
}

/* video.svg */
img[loading="lazy"].video {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NzYgNTEyIj48cGF0aCBmaWxsPSIjYWRiNWJkIiBkPSJNMzM2LjIgNjRINDcuOEMyMS40IDY0IDAgODUuNCAwIDExMS44djI4OC40QzAgNDI2LjYgMjEuNCA0NDggNDcuOCA0NDhoMjg4LjRjMjYuNCAwIDQ3LjgtMjEuNCA0Ny44LTQ3LjhWMTExLjhjMC0yNi40LTIxLjQtNDcuOC00Ny44LTQ3Ljh6bTE4OS40IDM3LjdMNDE2IDE3Ny4zdjE1Ny40bDEwOS42IDc1LjVjMjEuMiAxNC42IDUwLjQtLjMgNTAuNC0yNS44VjEyNy41YzAtMjUuNC0yOS4xLTQwLjQtNTAuNC0yNS44eiI+PC9wYXRoPjwvc3ZnPg==);
  background-size: 90% auto;
}
