.js-masonry > div {
  width: 150px;
}

.js-masonry > div.slide,
.js-masonry > div.view-slide,
.js-masonry > div.admin-entry {
  width: 100px;
}

.js-masonry > div.photographer {
  text-align: center;
  width: 180px;
}

.photographer {
  min-height: 270px;
}

.album {
  min-height: 230px;
}

.movie {
  min-height: 270px;
}

.slide {
  height: 115px;
}

.view-slide {
  height: 137px;
}

.entry {
  min-height: 170px;
}

.admin-entry {
  height: 115px;
}

.report {
  height: 350px;
  width: 230px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 25px;
}

.report .reason {
  height: 80px;
  overflow: scroll;
}

.report .video-thumb {
  height: 90px;
  width: 110px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
