.navbar-brand.dark {
  color: #6D94BF !important;
}

body.slideshow {
  background-color: #000000;
}

.btn-flag {
  margin-top: 30px;
  margin-right: 15px;
}