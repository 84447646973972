.about-block {
  & h3 { margin-bottom: 0; }
  & p { margin-bottom: 20px; }
}

a.dummy:hover {
  cursor: default !important;
  text-decoration: none !important;
}

#announcement {
  text-align: center;

  & > .alert {
    display: inline-block;
    padding: 10px 15px;

    & > a, & > span {
      display: inline-block;
      height: 2em;
      vertical-align: 0.35em;
      margin-left: 10px;
    }
  }
}

section.home {
  margin-top: 20px;

  & .js-masonry {
    margin: 0 auto;
  }
}

#home-slideshow-caption {
  width: 360px;
  min-height: 3em;
  margin: 15px auto 50px auto;
}

a.btn-caption {
  width: 100%;
  background-color: #FFFFFF;
  color: #446E9B !important;
  font-size: 1.2em;

  &:hover {
    background-color: #3E648D;
    color: #FFFFFF !important;
  }
}
