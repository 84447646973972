.photographers {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

table.photographers {
  max-width: 450px;
}
table.photographers:hover {
  cursor: pointer !important;
}
table.photographers td {
  vertical-align: middle !important;
}
table.photographers td.photographer-image {
  width: 110px !important;
}
table.photographers td h4 {
  text-align: center !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy9zaGFyZWQvY3NzIiwic291cmNlcyI6WyJpbmRleC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTs7QUFFQTtFQUNFOztBQUdGO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIucGhvdG9ncmFwaGVycyB7XG4gIHdpZHRoOiA4MCU7XG4gIG1hcmdpbi1sZWZ0OiBhdXRvO1xuICBtYXJnaW4tcmlnaHQ6IGF1dG87XG59XG5cbnRhYmxlLnBob3RvZ3JhcGhlcnMge1xuICBtYXgtd2lkdGg6IDQ1MHB4O1xuXG4gICY6aG92ZXIge1xuICAgIGN1cnNvcjogcG9pbnRlciAhaW1wb3J0YW50O1xuICB9XG5cbiAgJiB0ZCB7XG4gICAgdmVydGljYWwtYWxpZ246IG1pZGRsZSAhaW1wb3J0YW50O1xuXG4gICAgJi5waG90b2dyYXBoZXItaW1hZ2Uge1xuICAgICAgd2lkdGg6IDExMHB4ICFpbXBvcnRhbnQ7XG4gICAgfVxuXG4gICAgJiBoNCB7XG4gICAgICB0ZXh0LWFsaWduOiBjZW50ZXIgIWltcG9ydGFudDtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */