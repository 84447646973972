.gallery-title-holder {
  position: relative;
}

.gallery-back-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.gallery-description {
  margin: 25px 0;
  text-align: left;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy9nYWxsZXJpZXMvY3NzIiwic291cmNlcyI6WyJpbmRleC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuZ2FsbGVyeS10aXRsZS1ob2xkZXIge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG59XG5cbi5nYWxsZXJ5LWJhY2stYnV0dG9uIHtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICB0b3A6IDBweDtcbiAgcmlnaHQ6IDBweDtcbn1cblxuLmdhbGxlcnktZGVzY3JpcHRpb24ge1xuICBtYXJnaW46IDI1cHggMDtcbiAgdGV4dC1hbGlnbjogbGVmdDtcbn1cbiJdfQ== */