/* These are actual bugfixes or generic changes valid for any swatch. */

.navbar > .container {
  padding-left: 5px;
  padding-right: 5px;
}

.navbar-toggle {
  border: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 20px;
}

.form-group {
  min-height: 30px;
}

.pull-left img {
  padding-right: 15px;
}

.pull-right img {
  padding-left: 15px;
}

.nav-pills {
  margin-bottom: 25px;
}

tr.align-middle td,
td.align-middle {
  vertical-align: middle !important;
}

.table-borderless td,
.table-borderless th {
  border-top: 0 !important;
}

.checkbox label {
  font-weight: bold;
}
