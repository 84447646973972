#report_modal .modal-body {
  height: 240px;
}

#report_modal .modal-body textarea {
  resize: none;
}

textarea#report_content {
  height: auto;
}

.list-group-item .video-report-link,
.view-slide .photo-report-link {
  display: none;
}

.list-group-item:hover .video-report-link,
.view-slide:hover .photo-report-link {
  display: block;
}
